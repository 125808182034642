import Vue from "vue";
import Router from "vue-router";
import axios from "axios";
import store from "./store";

Vue.use(Router);
let router = new Router({
    mode: "history",
    routes: [{
            path: "/",
            props: true,
            component: () =>
                import ("./layout/Default"),
            meta: {
                requiresAuth: true,
            },
            children: [{
                    path: "",
                    name: "dashboard",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Dashboard/Dashboard"),
                },
                {
                    path: "/add",
                    name: "adds",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./layout/Admins/addMenu"),
                },
                {
                    path: "/CategoryList",
                    name: "CategoryList",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Category/category"),
                },
                {
                    path: "/TitleList",
                    name: "TitleList",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Title/title"),
                },
                {
                    path: "/volunteer",
                    name: "volunteer",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/volunteerTitle/title"),
                },
                {
                    path: "/Applications",
                    name: "Applications",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Applications/applications"),
                },
                {
                    path: "/volunteerApplication",
                    name: "volunteerApplication",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/volunteerApplication/applications"),
                },
                {
                    path: "/viewApplication",
                    name: "viewApplication",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Applications/viewApplication"),
                },
                {
                    path: "/viewVolunteerApplication",
                    name: "viewVolunteerApplication",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/volunteerApplication/viewApplication"),
                },
                //Employee document
                {
                    path: "/employeedocument",
                    name: "employeedocument",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/EmployeeDocument/documents"),
                },
                {
                    path: "/viewdocument",
                    name: "viewdocument",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/EmployeeDocument/viewDoc"),
                },




                {
                    path: "YourApplications",
                    name: "YourApplications",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Applications/documents"),
                },
                {
                    path: "YourDocInfo",
                    name: "YourDocInfo",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Applications/documentInfo"),
                },
                {
                    path: "/employeelist",
                    name: "employeelist",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/EmployeeList/list"),
                },
                {
                    path: "/employeeview",
                    name: "employeeview",

                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/EmployeeList/listView"),
                    props: true,
                },

                {
                    path: "/addEmployee",
                    name: "addEmployee",
                    props: true,
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Employee/addEmployee"),
                },
                {
                    path: "/viewEmployee",
                    name: "viewEmployee",
                    props: true,
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Employee/viewEmployee"),
                },
                {
                    path: "/Assign",
                    name: "Assign",
                    props: true,
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Employee/Assign"),
                },


                {
                    path: "/SubTeam",
                    name: "SubTeam",
                    props: true,
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Employee/SubTeam"),
                },

                {
                    path: "/SubTeam2",
                    name: "SubTeam2",
                    props: true,
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Employee/SubTeam2"),
                },




                {
                    path: "/ProductType",
                    name: "ProductType",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Products/ProductType/productType"),
                },
                {
                    path: "/addProducts",
                    name: "addProducts",
                    component: () =>
                        import ("./views/Products/addProduct"),
                },
                {
                    path: "/products",
                    name: "Products",
                    component: () =>
                        import ("./views/Products/products"),
                },
                {
                    path: "/promocode",
                    name: "Promocode",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Products/Promocode/promocode"),
                },
                {
                    path: "/product/:id",
                    name: "Product",
                    component: () =>
                        import ("./views/Products/product"),
                    props: true,
                },
                {
                    path: "/editProduct/:id",
                    name: "EditProduct",
                    component: () =>
                        import ("./views/Products/editProduct"),
                    props: true,
                },
                {
                    path: "/aboutUs",
                    name: "aboutUs",
                    component: () =>
                        import ("./views/About/about"),
                },
                {
                    path: "/Video",
                    name: "Video",
                    component: () =>
                        import ("./views/About/video"),
                },
                {
                    path: "/Announcements",
                    name: "Announcements",
                    component: () =>
                        import ("./views/About/announcements"),
                },
                {
                    path: "/purchaseReports",
                    name: "Purchases",
                    component: () =>
                        import ("./views/Reports/Products/purchase"),
                },

                {
                    path: "/quizDetails",
                    name: "quizDetails",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Contest/quizApplications"),
                },
                {
                    path: "/conservationClock",
                    name: "conservationClock",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/ConservationClock/conservationClock"),
                },
                {
                    path: "/viewQuiz",
                    name: "viewQuiz",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Contest/viewQuiz"),
                },

                // NEW Mod

                {
                    path: "/Roles",
                    name: "Roles",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/RolesandDepartments/roles"),
                },
                {
                    path: "/AddDivision",
                    name: "AddDivision",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Division/AddDivision"),
                },
                {
                    path: "/Departments",
                    name: "Departments",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/RolesandDepartments/departments"),
                },
                {
                    path: "/Projects",
                    name: "Projects",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Projects/projects"),
                },
                {
                    path: "/Positions",
                    name: "Positions",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/RolesandDepartments/positions"),
                },
                {
                    path: "/SpecialPosition",
                    name: "SpecialPosition",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/RolesandDepartments/SpecialPosition"),
                },

                {
                    path: "/Profile",
                    name: "Profile",
                    component: () =>
                        import ("./components/Profile/profile"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: "/ViewDA",
                    name: "ViewDA",
                    component: () =>
                        import ("./components/Profile/viewDA"),
                    meta: {
                        requiresAuth: true,
                    },
                },

                {
                    path: "/DisciplinaryActions",
                    name: "DisciplinaryActions",
                    component: () =>
                        import ("./views/DisciplinaryAction/disciplinaryActions"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: "/ApplyLoan",
                    name: "ApplyLoan",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Loans/ApplyLoan"),
                },
                {
                    path: "/ApplyAdvance",
                    name: "ApplyAdvance",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Loans/ApplyAdvance"),
                },
                {
                    path: "/LoanApplications",
                    name: "LoanApplications",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Loans/LoanApplications"),
                },
                {
                    path: "/ViewLoan",
                    name: "ViewLoan",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Loans/ViewLoan"),
                },


                {
                    path: "/MyLoanApplications",
                    name: "MyLoanApplications",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Loans/MyLoanApplications"),
                },

                {
                    path: "/MySalaryAdvanceApplications",
                    name: "MySalaryAdvanceApplications",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Loans/MySalaryAdvanceApplications"),
                },
                {
                    path: "/Questions",
                    name: "Questions",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Interviews/Questions"),
                },
                {
                    path: "/ExitInterview",
                    name: "ExitInterview",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Interviews/ExitInterview"),
                },
                {
                    path: "/AllExitInterviews",
                    name: "AllExitInterviews",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Interviews/AllApplications"),
                },
            ],
        },

        {
            path: "/Assets/",
            props: true,
            component: () =>
                import ("./layout/Default"),
            meta: {
                requiresAuth: true,
            },
            children: [{
                    path: "AssetCategory",
                    name: "AssetCategory",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Category/CategoryHome"),
                },
                {
                    path: "AddAsset",
                    name: "AddAsset",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Asset/AddAsset"),
                },
                {
                    path: "Assets",
                    name: "Assets",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Asset/AssetList"),
                },
                {
                    path: "ViewAsset",
                    name: "ViewAsset",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Asset/ViewAsset"),
                },
                {
                    path: "ViewLog",
                    name: "ViewLog",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Asset/LogList"),
                },
                {
                    path: "EditAsset",
                    name: "EditAsset",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Asset/EditAsset"),
                },
                {
                    path: "Purchase",
                    name: "Purchase",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Purchase/PurchaseList"),
                },
                {
                    path: "AddPurchase",
                    name: "AddPurchase",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Purchase/AddPurchase"),
                },
                {
                    path: "Inventory",
                    name: "Inventory",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Inventory/InventoryList"),
                },
                {
                    path: "AssignAsset",
                    name: "AssignAsset",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/AssignAsset/AssignAsset"),
                },

                {
                    path: "ReAssignAsset",
                    name: "ReAssignAsset",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/AssignAsset/ReAssignAsset"),
                },


                {
                    path: "DeptInventory",
                    name: "DeptInventory",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Inventory/DeptInventoryList"),
                },

                {
                    path: "EmpInventory",
                    name: "EmpInventory",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Inventory/EmpInventoryList"),
                },

                {
                    path: "DonorList",
                    name: "DonorList",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Donor/DonorList"),
                },





                {
                    path: "RequestList",
                    name: "RequestList",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Requests/RequestList"),
                },
                {
                    path: "NewRequest",
                    name: "NewRequest",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Requests/NewRequest"),
                },







                {
                    path: "Dismantle",
                    name: "Dismantle",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Dismantle/DismantleList"),
                },
                {
                    path: "AddDismantle",
                    name: "AddDismantle",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Dismantle/AddDismantle"),
                },
                {
                    path: "MyQuery",
                    name: "MyQuery",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Query/EmpQueriesList"),
                },
                {
                    path: "HRQuery",
                    name: "HRQuery",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Query/HRQueriesList"),
                },

                {
                    path: "StockConsumed",
                    name: "StockConsumed",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Assets/Dismantle/StockConsumed"),
                },
            ],
        },

        //Awards
        {
            path: "/Awards/",
            props: true,
            component: () =>
                import ("./layout/Default"),
            meta: {
                requiresAuth: true,
            },
            children: [{
                path: "AwardList",
                name: "AwardList",
                meta: {
                    requiresAuth: true,
                },
                component: () =>
                    import ("./views/Awards/AwardList"),
            }, ],
        },
        //Policies
        {
            path: "/HRmanual",
            props: true,
            component: () =>
                import ("./layout/Default"),
            meta: {
                requiresAuth: true,
            },
            children: [{
                    path: "addPolicies",
                    name: "addpolicies",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Policies/addPolicies"),
                },
                {
                    path: "viewPolicies",
                    name: "viewPolicies",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Policies/viewPolicies"),
                },
            ],
        },
        //perforance management reviews PMR
        {
            path: "/PMR",
            props: true,
            component: () =>
                import ("./layout/Default"),
            meta: {
                requiresAuth: true,
            },
            children: [{
                    path: "addPMR",
                    name: "addPMR",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/PMR/addPMR"),
                },
                {
                    path: "viewPMR",
                    name: "viewPMR",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/PMR/viewPMR"),
                },
            ],
        },
        //Attendance
        {
            path: "/Attendance",
            props: true,
            component: () =>
                import ("./layout/Default"),
            meta: {
                requiresAuth: true,
            },
            children: [{
                    path: "teamAttendance",
                    name: "teamAttendance",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/teamAttendance"),
                },
                {
                    path: "leaveRequests",
                    name: "leaveRequests",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/leaveRequests"),
                },
                {
                    path: "leaveRequestsDetails",
                    name: "leaveRequestsDetails",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/leaveRequestsDetails"),
                },
                {
                    path: "organizationDetails",
                    name: "organizationDetails",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/organizationDetails"),
                },
                {
                    path: "travelRequests",
                    name: "travelRequests",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/travelRequests"),
                },
                {
                    path: "travelRequestDetails",
                    name: "travelRequestDetails",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/travelRequestDetails"),
                },
                {
                    path: "addAssignees",
                    name: "addAssignees",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/addAssignees"),
                },
                {
                    path: "myTravelogue",
                    name: "myTravelogue",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/myTravelogue"),
                },
                {
                    path: "myTravelDetails",
                    name: "myTravelDetails",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/myTravelDetails"),
                },
                {
                    path: "travelRequestForm",
                    name: "travelRequestForm",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/travelRequestForm"),
                },
                {
                    path: "myAttendance",
                    name: "myAttendance",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/myAttendance"),
                },
                {
                    path: "myLeaves",
                    name: "myLeaves",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/myLeaves"),
                },
                {
                    path: "leaveForm",
                    name: "leaveForm",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/leaveForm"),
                },
                {
                    path: "leaveAddorUpdate",
                    name: "leaveAddorUpdate",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/leaveAddorUpdate"),
                },
                {
                    path: "assignDetails",
                    name: "assignDetails",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/assignDetails"),
                },
                {
                    path: "teamMembers",
                    name: "teamMembers",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/teamMembers"),
                },
                {
                    path: "teamMemberDetails",
                    name: "teamMemberDetails",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/teamMemberDetails"),
                },
                {
                    path: "teamMemberAttendance",
                    name: "teamMemberAttendance",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/teamMemberAttendance"),
                },
                {
                    path: "reportList",
                    name: "reportList",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./views/Attendance/reportList"),
                },
            ],
        },
        {
            path: "/",
            props: true,
            component: () =>
                import ("./layout/Authentication"),
            meta: {
                requiresAuth: false,
            },
            children: [{
                    path: "login",
                    name: "login",
                    meta: {
                        requiresAuth: false,
                    },
                    component: () =>
                        import ("./views/Authentication/Login"),
                },
                {
                    path: "signup",
                    name: "signup",
                    meta: {
                        requiresAuth: false,
                    },
                    component: () =>
                        import ("./views/Authentication/Signup"),
                },
            ],
        },
        {
            path: "/servererror",
            name: "servererror",
            props: true,
            component: () =>
                import ("./layout/500"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "*",
            name: "404pagenotfound",
            props: true,
            component: () =>
                import ("./layout/404"),
            meta: {
                requiresAuth: false,
            },
        },
    ],
    scrollBehavior() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },
});
router.beforeEach((to, from, next) => {
    const leavingPage = ['addEmployee', 'viewEmployee'].includes(from.name);

    // Check if not entering any of these pages
    const notEnteringPage = !['addEmployee', 'viewEmployee'].includes(to.name);

    // If leaving any of these pages and not entering the same type of page
    if (leavingPage && notEnteringPage) {
        // Commit a Vuex store mutation to change the current page to an empty string
        store.commit('changeCurrentPage', '');
        // console.log('Change current page to an empty string');
    }
    const leavingDateStatus = ['leaveRequests', 'leaveRequestsDetails'].includes(from.name);

    // Check if not entering any of these pages
    const notEnteringDateStatus = !['leaveRequests', 'leaveRequestsDetails'].includes(to.name);
    if (leavingDateStatus && notEnteringDateStatus) {
        // Commit a Vuex store mutation to change the current page to an empty string
        store.commit('changeStatus', '');
        store.commit('changeDate', '');
        // console.log('Change current page to an empty string');
    }
    const leavingTravelDateStatus = ['travelRequests', 'travelRequestDetails'].includes(from.name);

    // Check if not entering any of these pages
    const notEnteringTravelDateStatus = !['travelRequests', 'travelRequestDetails'].includes(to.name);
    if (leavingTravelDateStatus && notEnteringTravelDateStatus) {
        // Commit a Vuex store mutation to change the current page to an empty string
        store.commit('changeTravelStatus', '');
        store.commit('changeTravelDate', '');
        // console.log('Change current page to an empty string');
    }
    const leavingPageTeam = ['teamMembers', 'teamMemberDetails'].includes(from.name);

    // Check if not entering any of these pages
    const notEnteringPageTeam = !['teamMembers', 'teamMemberDetails'].includes(to.name);
    if (leavingPageTeam && notEnteringPageTeam) {
        // Commit a Vuex store mutation to change the current page to an empty string
        store.commit('changeCurrentPageTeam', '');
        // console.log('Change current page to an empty string');
    }
    //new lines
    localStorage.setItem("PRE", from.path);

    // end  of newely added lines
    if (
        to.matched.some((route) => route.meta.requiresAuth == true) &&
        store.state.isLoggedIn == false
    ) {
        console.log(from);
        next({ name: "login", params: { lastPage: from } });
        return;
    }
    if (store.state.isLoggedIn == true) {
        axios({
                method: "GET",
                url: "employee/profile/me",
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
            })
            .then((response) => {
                if (!response.data.status) {
                    store.commit("sessionOut", true);
                    return;
                }
            })
            .catch((err) => {
                var msg = err;
                console.log(msg);
                // store.commit("sessionOut", true);
            });
    }
    if (
        (to.name == "login" || to.name == "home") &&
        store.state.isLoggedIn == true
    ) {
        next({ name: "dashboard" });
        return;
    }
    next();
});

export default router;